import React, { useState, useEffect } from 'react'
import * as styles from './index.module.less'

interface ButtonLink {
  href: string
  text: string
  className?: string
}

interface HeaderProps {
  logoSrc: string
  logoAlt?: string
  buttonLinks: ButtonLink[]
  isMb?: boolean
}

const Header: React.FC<HeaderProps> = ({ isMb = false, logoSrc, logoAlt = '', buttonLinks }) => {
  const [isShowMenu, setIsShowMenu] = useState(false)

  useEffect(() => {
    const closeMenu = (e: Event) => {
      if (!e.target || !(e.target as Element).closest('[data-header-wrapper]')) {
        setIsShowMenu(false)
      }
    }
    document.addEventListener('click', closeMenu)

    return () => {
      document.removeEventListener('click', closeMenu)
    }
  }, [])

  const toggleMenu = () => {
    setIsShowMenu(!isShowMenu)
  }

  return (
    <div className={styles.headerWrapper} data-header-wrapper>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logoSrc} alt={logoAlt} />
        </div>
        {!isMb ? (
          <div className={styles.buttons}>
            {buttonLinks.map((link, index) => (
              <a key={index} className={link.className || styles.btn} href={link.href}>
                {link.text}
              </a>
            ))}
          </div>
        ) : (
          <>
            <div className={styles.icon} onClick={toggleMenu}>
              <div></div>
              <div></div>
              <div></div>
            </div>

            <div className={styles.menu} style={{ height: isShowMenu ? '11rem' : '0' }}>
              {buttonLinks.map((link, index) => (
                <a key={index} className={`${link.className} ${styles.btn}`} href={link.href}>
                  {link.text}
                </a>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Header
