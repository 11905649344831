import React from 'react'
import * as styles from './index.module.less'

interface ItemProps {
  title: string[]
  des?: string[]
  titleStyle?: React.CSSProperties
  desStyle?: React.CSSProperties
}

const Item: React.FC<ItemProps> = ({ title, des = [], titleStyle, desStyle }) => {
  return (
    <div className={styles.juriesItemWrapper}>
      {title.map((t, index) => (
        <div key={index} style={titleStyle} className={styles.title}>
          {t}
        </div>
      ))}
      {des.map((d, index) => (
        <div key={index} style={desStyle} className={styles.des}>
          {d}
        </div>
      ))}
    </div>
  )
}

interface JuriesTagListProps {
  data: {
    title: string[]
    des?: string[]
  }[]
}

const JuriesTagList: React.FC<JuriesTagListProps> = ({ data }) => {
  return (
    <div className={styles.juriesTagList}>
      {data.map((d, index) => (
        <Item key={index} {...d}></Item>
      ))}
    </div>
  )
}

export default JuriesTagList
