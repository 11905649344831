import React from 'react'
import Qrcode from './img/qrcode.png'
import * as styles from './index.module.less'

interface FooterProps {
  [x: string]: any
}

const Footer: React.FC<FooterProps> = ({ isMb }) => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.qrcode}>
        <img src={Qrcode} alt="" />
        <span>扫码咨询星斗奖相关问题</span>
      </div>
      <div className={styles.footer}>
        {isMb ? (
          <span>神策网络科技（北京）有限公司</span>
        ) : (
          <>
            <span>© 2022 神策网络科技（北京）有限公司 京ICP备15055677号</span>
            <span> 京公网安备 11010802027887号</span>
            <span>广播电视节目制作经营许可证（京）字第22320号</span>
          </>
        )}
      </div>
    </div>
  )
}
export default Footer
