import React from 'react'
import * as styles from './index.module.less'

interface ButtonProps {
  text: string
  href: string
  backgroundImg?: string
}

interface BannerProps {
  backgroundImg: string
  titleImg: string
  titleAlt?: string
  description: string
  buttons: ButtonProps[]
}

const Banner: React.FC<BannerProps> = ({ backgroundImg, titleImg, titleAlt = '', description, buttons }) => {
  return (
    <div className={styles.bannerWrapper} style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className={styles.content}>
        <div className={styles.title}>
          <img src={titleImg} alt={titleAlt} />
        </div>
        <div className={styles.des}>{description}</div>
        <div className={styles.buttons}>
          {buttons.map((button, index) => (
            <div
              key={index}
              className={index === 0 ? styles.btn1 : styles.btn2}
              onClick={() => window.open(button.href, '_blank')}
              style={button.backgroundImg ? { backgroundImage: `url(${button.backgroundImg})` } : {}}
            >
              <span>{button.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Banner
