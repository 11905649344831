import React from 'react'
import Layout from 'layouts/zh'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import { Seo } from 'components/index'
import {
  Header,
  Banner,
  BriefDescription,
  SummaryTitle,
  JuriesTagList,
  AwardList,
  Process,
  Footer,
} from 'components/Topic2023/Ds2023'

import ImageSwiper from 'components/Topic/ImageSwiper'

import 'swiper/swiper-bundle.css'
import * as styles from './index.module.less'

import Logo from 'assets/images/topic/ds2023star/logo.svg'
import BannerImg from 'assets/images/topic/ds2023star/banner.png'
import BannerMbImg from 'assets/images/topic/ds2023star/bannerMb.png'
import TitleIcon from 'assets/images/topic/ds2023star/title.svg'
import bannerBtn from 'assets/images/topic/ds2023star/bannerBtn.svg'
import part3_1 from 'assets/images/topic/ds2023star/part3_1.png'
import part3_2 from 'assets/images/topic/ds2023star/part3_2.png'
import part3_3 from 'assets/images/topic/ds2023star/part3_3.png'
import part3_4 from 'assets/images/topic/ds2023star/part3_4.png'
import part3_5 from 'assets/images/topic/ds2023star/part3_5.png'
import part3_6 from 'assets/images/topic/ds2023star/part3_6.png'

const imageList = [
  require('assets/images/topic/ds2023star/part1_1.png').default,
  require('assets/images/topic/ds2023star/part1_2.png').default,
  require('assets/images/topic/ds2023star/part1_3.png').default,
  require('assets/images/topic/ds2023star/part1_4.png').default,
  require('assets/images/topic/ds2023star/part1_5.png').default,
  require('assets/images/topic/ds2023star/part1_6.png').default,
]

const imageListMb = [
  require('assets/images/topic/ds2023star/part1_1mb.png').default,
  require('assets/images/topic/ds2023star/part1_2mb.png').default,
  require('assets/images/topic/ds2023star/part1_3mb.png').default,
  require('assets/images/topic/ds2023star/part1_4mb.png').default,
  require('assets/images/topic/ds2023star/part1_5mb.png').default,
  require('assets/images/topic/ds2023star/part1_6mb.png').default,
]

const briefDescription = [
  {
    text: '星斗奖的诞生旨在鼓励走在数据驱动实践前沿的企业机构及突出贡献者，',
    color: '#D1893C',
    weight: 500,
  },
  {
    text: '通过数据驱动大会这一平台更积极地传播交流数据驱动行业发展的新态势，共同分享典型案例成果，进而推动整个中国大数据基础建设与行业应用进程。',
    lineBreak: true,
  },
  {
    text: '2017 – 2022 年，已历经六届星斗奖的评选，',
    color: '#D1893C',
    weight: 500,
  },
  {
    text: '上千家在数据驱动前沿的各行业头部企业及突出贡献者参与和获得了星斗奖。',
    lineBreak: true,
  },
  {
    text: '2023 年 10 月，神策数据携手各位评审专家即将进行第七届星斗奖的评选，',
    color: '#D1893C',
    weight: 500,
  },
  {
    text: '并在第八届数据驱动大会上进行颁奖典礼。',
  },
]

const juriesList = [
  {
    title: ['中国信通院', '泰尔终端实验室'],
    des: ['信息安全部副主任'],
  },
  {
    title: ['国家工业信息', '安全发展研究中心'],
    des: ['信息政策所副所长'],
  },
  {
    title: ['全国信息安全技术', '标准工作委员会'],
    des: ['副秘书长'],
  },
  {
    title: ['北京信息化协会'],
    des: ['信息技术应用创新', '工作委员会秘书长'],
  },
  {
    title: ['清华大学'],
    des: ['大数据国家工程实验室', '总工'],
  },
  {
    title: ['北京邮电大学'],
    des: ['信息安全学院教授'],
  },
  {
    title: ['经开区国家信创园'],
    des: ['产业促进部部长'],
  },
  {
    title: ['中互金投基金', '管理有限公司'],
  },
  {
    title: ['中国信息通信研究院'],
    des: ['云计算与大数据所', '大数据与区块链部副主任'],
  },
  {
    title: ['中国社会科学院'],
    des: ['金融研究所', '金融科技研究室副主任'],
  },
  {
    title: ['国家金融与发展', '实验室'],
  },
  {
    title: ['中国软件评测中心'],
    des: ['信创中心主任'],
  },
  {
    title: ['海淀商联会技术', '专家委员会'],
    des: ['主任'],
  },
]

const AwardList1 = {
  title: '数据驱动 · 企业奖',
  data: [
    {
      imgSrc: part3_1,
      title: '行业领军企业奖',
      des: '在行业数字化赋能客户旅程经营上，具备很强的行业引领作用。',
      href: 'http://sensorsform.mikecrm.com/pBWjsj4',
    },
    {
      imgSrc: part3_2,
      title: '行业创新企业奖',
      des: '在行业数据驱动和创新应用上具备新锐的应用场景和实践案例。',
      href: 'http://sensorsform.mikecrm.com/pBWjsj4',
    },
    {
      imgSrc: part3_3,
      title: '营销创新企业奖',
      des: '在数字营销领域具有创新性、执行性、可持续性，实现显著商业价值。',
      href: 'http://sensorsform.mikecrm.com/pBWjsj4',
    },
    {
      imgSrc: part3_4,
      title: '信创实践先锋企业奖',
      des: '具备非常强的信创实践和数据安全意识，在信创实践及数据安全合规的落地上有着行业领先作用。',
      href: 'http://sensorsform.mikecrm.com/pBWjsj4',
    },
  ],
}

const AwardList2 = {
  title: '数据驱动 · 人物奖	',
  data: [
    {
      imgSrc: part3_5,
      title: '领军人物奖',
      des: '具备非常强的数据驱动意识，对推动企业数据体系建设、数据化进程、客户体验优化、营销创新方面有着主导性的推进作用。',
      href: 'http://sensorsform.mikecrm.com/E2Thq1A',
    },
    {
      imgSrc: part3_6,
      title: '菁英人物奖',
      des: '在数据分析、数据驱动、营销创新方面有着丰富的场景化落地经验并取得显著的对实际业务助推的成果。',
      href: 'http://sensorsform.mikecrm.com/E2Thq1A',
    },
  ],
}

const stepData = [
  {
    title: '征集',
    des: '2023.9.25-10.08',
  },
  {
    title: '评审',
    des: '2023.10.09',
  },
  {
    title: '公示',
    des: '2023.10.16-10.20',
  },
  {
    title: '颁奖',
    des: '2023.10.27',
  },
]
const DS2023Star: React.FC = () => {
  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout
      header={
        <Header
          logoSrc={Logo}
          logoAlt="Logo Description"
          buttonLinks={[
            { href: 'https://www.sensorsdata.cn/topic/ds2023.html', text: '大会首页', className: styles.btn1 },
            { href: 'https://www.sensorsdata.cn/', text: '官网首页', className: styles.btn2 },
          ]}
          isMb={isMb}
        />
      }
      hideFooter={true}
      showFloat={false}
      showPhoneCall={false}
      showBackTop={false}
    >
      <Seo
        title="第七届「星斗奖」申报入口 | 神策数据"
        description="神策数据“星斗奖”旨在鼓励走在数据驱动实践前沿的企业机构及突出贡献者，推动中国大数据基础建设与行业应用进程。历经六届评选，上千家头部企业参与获得殊荣。2023年第七届星斗奖申报通道开启，欢迎企业及个人报名参选！"
        keywords="星斗奖评选,报名入口,数据驱动大会,神策数据,评审专家,奖项设置"
        saTitle="首页-2023数据驱动大会-星斗奖"
      />
      <div className={styles.dsRoot}>
        <Banner
          backgroundImg={isMb ? BannerMbImg : BannerImg}
          titleImg={TitleIcon}
          titleAlt="星斗奖"
          description="数据如浩瀚繁星，驱动人类勇往前行"
          buttons={[
            { href: 'http://sensorsform.mikecrm.com/pBWjsj4', text: '企业报名' },
            { href: 'http://sensorsform.mikecrm.com/E2Thq1A', text: '个人报名', backgroundImg: bannerBtn },
          ]}
        />
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <SummaryTitle title="星斗奖简介" des="INTRODUCTION" className={isMb ? styles.pt0 : ''} />
            <div className={styles.part1}>
              <div className={styles.des}>
                <BriefDescription descriptions={briefDescription} />
              </div>
              <div className={styles.imageWrapper}>
                <ImageSwiper dataSource={isMb ? imageListMb : imageList} />
              </div>
            </div>
            <SummaryTitle title="历届评审专家团队" des="JURIES" />
            <JuriesTagList data={juriesList} />
            <SummaryTitle title="星斗奖奖项设置" des="TYPES OF AWARDS" />
            <AwardList isMb={isMb} dataSource={AwardList1}></AwardList>
            <AwardList isMb={isMb} dataSource={AwardList2}></AwardList>
            <SummaryTitle title="奖项征集流程" des="PROCESS" className={isMb ? styles.pb0 : ''} />
            <Process data={stepData} />
            <Footer isMb={isMb}></Footer>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DS2023Star
