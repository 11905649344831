// extracted by mini-css-extract-plugin
export var btn1 = "index-module--btn1--BSXjo";
export var btn2 = "index-module--btn2--BOY4y";
export var content = "index-module--content--P+Iyu";
export var contentWrapper = "index-module--contentWrapper--g6uNi";
export var des = "index-module--des--h-sd7";
export var dsRoot = "index-module--dsRoot--xnE-f";
export var imageWrapper = "index-module--imageWrapper--2mc8u";
export var part1 = "index-module--part1--Y2e6q";
export var pb0 = "index-module--pb0--eZ2XS";
export var pt0 = "index-module--pt0--opWz6";