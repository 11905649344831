import React from 'react'
import * as styles from './index.module.less'

interface ItemProps {
  icon?: React.ReactNode
  title: string
  des: string
}

const Item: React.FC<ItemProps> = ({ icon, title, des }) => {
  return (
    <div className={styles.itemWrapper}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.des}>{des}</div>
      </div>
    </div>
  )
}

interface ProcessProps {
  data?: ItemProps[]
}

const Process: React.FC<ProcessProps> = ({ data = [] }) => {
  return (
    <div className={styles.processWrapper}>
      <div className={styles.line}></div>
      <div className={styles.list}>
        {data.map((d, index) => (
          <Item key={index} {...d} />
        ))}
      </div>
    </div>
  )
}

export default Process
