import React from 'react'
import * as styles from './index.module.less'

const BriefDescription = ({ descriptions }) => {
  return (
    <div className={styles.desWrapper}>
      {descriptions.map((desc, index) => (
        <React.Fragment key={index}>
          <span
            style={{
              ...(desc.color && {
                color: desc.color,
              }),
              ...(desc.weight && {
                fontWeight: desc.weight,
              }),
            }}
          >
            {desc.text}
          </span>
          {desc.lineBreak && <br />}
        </React.Fragment>
      ))}
    </div>
  )
}

export default BriefDescription
