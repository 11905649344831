import React from 'react'

import * as styles from './index.module.less'

interface SummaryTitleProps {
  title: string
  des?: string
  className?: string
}

const SummaryTitle: React.FC<SummaryTitleProps> = ({ title, des, className = '' }) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <span className={styles.title}>{title}</span>
      {des && (
        <>
          <span className={styles.split}>/</span>
          <span className={styles.des}>{des}</span>
        </>
      )}
    </div>
  )
}

export default SummaryTitle
