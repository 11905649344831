import React from 'react'
import BorderMb2 from './img/borderMb2.png'
import * as styles from './index.module.less'

interface ItemProps {
  imgSrc: string
  imgAlt?: string
  title: string
  des: string
  buttonText?: string
  href?: string
  style?: React.CSSProperties
}

const Item: React.FC<ItemProps> = ({ imgSrc, imgAlt, title, des, style = {}, buttonText = '立即报名', href = '' }) => {
  return (
    <div className={styles.itemWrapper} style={style}>
      <div className={styles.img}>
        <img src={imgSrc} alt={imgAlt ?? title} />
      </div>
      <div className={styles.content}>
        <div className={styles.itemTitle}>{title}</div>
        <div className={styles.des}>{des}</div>
      </div>
      <button className={styles.button} onClick={() => window.open(href, '_blank')}>
        {buttonText}
      </button>
    </div>
  )
}

interface AwardListProps {
  dataSource: {
    title: string
    data: ItemProps[]
  }
  isMb?: boolean
}

const AwardList: React.FC<AwardListProps> = ({ dataSource: { title, data }, isMb = false }) => {
  return (
    <div
      className={styles.awardListWrapper}
      style={isMb && data.length === 2 ? { background: `url(${BorderMb2}) 0 0 / 100% 100%` } : undefined}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.list}>
        {data.map((d, index) => (
          <Item key={index} {...d} style={data.length < 4 ? { width: '320px' } : {}} />
        ))}
      </div>
      {isMb && (
        <button className={styles.button} onClick={() => window.open(data[0]?.href || '#', '_blank')}>
          {data[0]?.buttonText || '立即报名'}
        </button>
      )}
    </div>
  )
}

export default AwardList
